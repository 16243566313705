import Swal from "sweetalert2";
import HelperMixin from '../../Shared/HelperMixin.vue';
import PaginationHelper from '../../Shared/PaginationHelper.vue';
export default {
    name: 'Courses',
    mixins: [HelperMixin],
    components: {
        PaginationHelper
    },

    async created() {
        await this.CheckLoginStatus();
        await this.GetAllCities()
        /*await this.GetAllMunicipalities()*/

        this.GetInfo();
        this.GetSoilAnalysis();
        this.GetSoilAnalysisInfo();
        this.GetWaterAnalysis();
        this.GetWaterAnalysisInfo();
        this.GetGroup();

    },

    computed: {
        totalPages() {
            return Math.ceil(this.pages / this.pageSize);
        }
    },

    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Search: '',

            ScreenTitle: ' المزارع  ',
            ScreenTitleSingle: ' مزرعة ',
            ScreenTitleSingleAl: 'المزرعة ',

            Info: [],
            Cities: [],
            Statistics: [],
            SoilAnalysis: [],
            SoilAnalysisInfo: [],
            WaterAnalysis: [],
            WaterAnalysisInfo: [],
            Group:[],
            AddDialog: false,
            AddSoilAnalysisDialog : false,
            AddFarmesSoilAnalysisInfoDialog : false,
            AddWaterAnalysisDialog: false,
            AddWaterAnalysisInfoDialog: false,
            AddGroupDialog: false,

            SelectedItem: '',
            



            ruleForm: {
                Id: '',
                ParentId: '',
                MunicipalitiesId: '',
                CoordinateX: '', // القيمة الافتراضية لعدد الأعمدة
                CoordinateY: '' , // القيمة الافتراضية لعدد الصفوف
                Name: '',
                Owner: '',
                Phone: '',
                Email: '',
                Spase: '',
                Notes:'',
               
            },
            ruleForms: {
                Id: '',
              
                FarmesId: '',
                SampleType: '',
                Service: '',
                Company: '',
                InvoiceDate: '',
                InvoiceNumber: '',
                Price: '',
            },
            ruleFormm: {
                Id: '',

                FarmesSoilAnalysisId: '',
                Test: '',
                Symbol: '',
                Units: '',
                Results: '',
               
            },

            ruleFormss: {
                Id: '',

                FarmesId: '',
                SampleType: '',
                Service: '',
                Company: '',
                InvoiceDate: '',
                InvoiceNumber: '',
                Price: '',
            },

            ruleFormms: {
                Id: '',

                FarmesWaterAnalysisId: '',
                Type: '',
                Parameters: '',
                AnalysisMethods: '',
                Units: '',
                Results: '',
                LibyanGroundwater: '',

            },
            ruleFormGroup: {
                Id: '',
                Name:'',
                FarmesId:'',
                CoordinateX: '',
                CoordinateY: '',
              

            },

            rules: {
                MunicipalitiesId: this.$helper.Required(),
                CoordinateX: this.$helper.Required(),
                CoordinateY: this.$helper.Required(),
                SampleType: this.$helper.Required(),
                Service: this.$helper.Required(),
                Company: this.$helper.Required(),
                InvoiceNumber: this.$helper.Required(),
                Price: this.$helper.Required(),
                Test: this.$helper.Required(),
                Symbol: this.$helper.Required(),
                Units: this.$helper.Required(),
                Results: this.$helper.Required(),
                Parameters: this.$helper.Required(),
                AnalysisMethods: this.$helper.Required(),
                LibyanGroundwater: this.$helper.Required(),

                Name: this.$helper.Required(),
                Owner: this.$helper.Required(),
                Spase: this.$helper.Required(),
                Phone: this.$helper.Phone(),
           
                Email: this.$helper.EmailOnly(),
            },

         


        };
    },



    methods: {
        ClearGroup() {
            this.ruleFormGroup.Name = '';
            this.ruleFormGroup.CoordinateX = '';
            this.ruleFormGroup.CoordinateY = '';
        },
        async GetAllMunicipalitiess() {
            this.GetAllMunicipalities(this.ruleForm.ParentId)
        },
        createTable() {
            const rows = this.ruleFormGroup.CoordinateY;
            const cols = this.ruleFormGroup.CoordinateX;
            const farmDiv = document.getElementById('farm');

            // مسح المحتوى السابق
            farmDiv.innerHTML = '';

            // إنشاء الجدول
            const table = document.createElement('table');
            for (let i = 0; i < rows; i++) {
                const row = document.createElement('tr');
                for (let j = 0; j < cols; j++) {
                    const cell = document.createElement('td');
                    // إضافة النخيل (رمز) في الخلايا
                    cell.innerHTML = '🌴';
                    cell.className = 'palm'; // تعيين الصنف
                    row.appendChild(cell);
                }
                table.appendChild(row);
            }
            farmDiv.appendChild(table);
        },
        
        mounted() {
            this.createTable(); // إنشاء الجدول عند تحميل الصفحة
        },



        GetSoilAnalysis(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

                this.$blockUI.Start();
            this.$http.GetSoilAnalysis(this.pageNo, this.pageSize, this.ruleForms.FarmesId)
                .then(response => {
                        this.$blockUI.Stop();

                    this.SoilAnalysis = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetGroup(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

                this.$blockUI.Start();
            this.$http.GetGroup(this.pageNo, this.pageSize, this.ruleFormGroup.FarmesId)
                .then(response => {
                        this.$blockUI.Stop();

                    this.Group = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetSoilAnalysisInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetSoilAnalysisInfo(this.pageNo, this.pageSize, this.ruleFormm.FarmesSoilAnalysisId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.SoilAnalysisInfo = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },


        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (!this.Search)
                this.$blockUI.Start();
            this.$http.GetFarmes(this.pageNo, this.pageSize, this.Search)
                .then(response => {
                    if (!this.Search)
                        this.$blockUI.Stop();

                    this.Info = response.data.info;
                    this.pages = response.data.count;
                    this.Statistics = response.data.statistics;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(0);
                    this.ruleForm.Spase = Number(this.ruleForm.Spase);
                    this.ruleForm.CoordinateX = Number(this.ruleForm.CoordinateX);
                    this.ruleForm.CoordinateY = Number(this.ruleForm.CoordinateY);
                    this.$blockUI.Start();
                    this.$http.AddFarmes(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();

                            this.ClearForm(this.ruleForm);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },


        submitSoilAnalysisForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    
                    this.ruleForms.FarmesId = Number(this.ruleForms.FarmesId);
                    this.ruleForms.Id = Number(0);
                    this.ruleForms.Price = Number(this.ruleForms.Price);
                    
                    this.$blockUI.Start();
                    this.$http.AddSoilAnalysis(this.ruleForms)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetSoilAnalysis();

                            this.ClearForm(this.ruleForms);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitFarmesSoilAnalysisInfo(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleFormm.FarmesSoilAnalysisId = Number(this.ruleFormm.FarmesSoilAnalysisId);
                    this.ruleFormm.Id = Number(0);
                  

                    this.$blockUI.Start();
                    this.$http.AddSoilAnalysisInfo(this.ruleFormm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetSoilAnalysisInfo();

                            this.ClearForm(this.ruleFormm);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        submitGroupForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleFormGroup.Id = Number(0);
                    this.ruleFormGroup.FarmesId = Number(this.ruleFormGroup.FarmesId);
                    this.ruleFormGroup.CoordinateX = Number(this.ruleFormGroup.CoordinateX);
                    this.ruleFormGroup.CoordinateY = Number(this.ruleFormGroup.CoordinateY);
                    this.$blockUI.Start();
                    this.$http.AddGroup(this.ruleFormGroup)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.ClearGroup();
                            this.GetGroup();
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },


        OpentEditDialog(item) {
            this.SelectedItem = item;
            this.ruleForm.Id = item.id;
            this.ruleForm.MunicipalitiesId = item.municipalitiesId;
            this.ruleForm.ParentId = item.citiesName;
            this.ruleForm.CoordinateX = item.coordinateX;
            this.ruleForm.CoordinateY = item.coordinateY;
            this.ruleForm.Phone = item.phone;
            this.ruleForm.Name = item.name;
            this.ruleForm.Owner = item.owner;
            this.ruleForm.Email = item.email;
            this.ruleForm.Spase = item.spase;
            this.ruleForm.Notes = item.notes;

        },

        OpenAddSoilAnalysisDialog(item) {
            this.SelectedItem = item;
            this.ruleForms.FarmesId = item.id;
            this.ruleForms.SampleType = '';
            this.ruleForms.Service = '';
            this.ruleForms.Company = '';
            this.ruleForms.InvoiceDate = '';
            this.ruleForms.InvoiceNumber = '';
            this.ruleForms.Price = '';
            this.AddPalmsFertilizationDialog = true;
            this.GetSoilAnalysis();

        },
        OpenAddFarmesSoilAnalysisInfoDialog(item) {
            this.SelectedItem = item;
            this.ruleFormm.FarmesSoilAnalysisId = item.id;
            this.ruleFormm.Test = '';
            this.ruleFormm.Symbol = '';
            this.ruleFormm.Units = '';
            this.ruleFormm.Results = '';
            this.AddFarmesSoilAnalysisInfoDialog = true;
            this.GetSoilAnalysisInfo();
        },
        ShowaSoilAnalysisDialog(item) {
            this.SelectedItem = item;
            this.ruleForms.Id = item.id;
            this.ruleForms.FarmesId = item.farmesId;
            this.ruleForms.SampleType = item.sampleType;
            this.ruleForms.Service = item.service;
            this.ruleForms.Company = item.company;
            this.ruleForms.InvoiceDate = item.invoiceDate;
            this.ruleForms.InvoiceNumber = item.invoiceNumber;
            this.ruleForms.Price = item.price;
            

        },

        ShowaSoilAnalysisInfoDialog(item) {
            this.SelectedItem = item;
            this.ruleFormm.Id = item.id;
            this.ruleFormm.FarmesSoilAnalysisId = item.farmesSoilAnalysisId;
            this.ruleFormm.Test = item.test;
            this.ruleFormm.Symbol = item.symbol;
            this.ruleFormm.Units = item.units;
            this.ruleFormm.Results = item.results;
           
        },
      

        submitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    this.ruleForm.Spase = Number(this.ruleForm.Spase);
                    this.ruleForm.CoordinateX = Number(this.ruleForm.CoordinateX);
                    this.ruleForm.CoordinateY = Number(this.ruleForm.CoordinateY);
                    this.$blockUI.Start();
                    this.$http.EditFarmes(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.ClearForm(this.ruleForm);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        submitEditGroupForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleFormGroup.Id = Number(this.ruleFormGroup.Id);
                    this.ruleFormGroup.CoordinateX = Number(this.ruleFormGroup.CoordinateX);
                    this.ruleFormGroup.CoordinateY = Number(this.ruleFormGroup.CoordinateY);
                    this.$blockUI.Start();
                    this.$http.EditGroup(this.ruleFormGroup)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.ClearGroup();
                            this.GetGroup();
                            this.EditGropDialog = false;
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        submitEditSoilAnalysisForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForms.Id = Number(this.ruleForms.Id);
                    this.ruleForms.FarmesId = Number(this.ruleForms.FarmesId);
                    this.ruleForms.Price = Number(this.ruleForms.Price);
                   
                    this.$blockUI.Start();
                    this.$http.EditSoilAnalysis(this.ruleForms)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetSoilAnalysis();
                            this.ClearForm(this.ruleForms);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitEditFarmesSoilAnalysisInfoForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleFormm.Id = Number(this.ruleFormm.Id);
                    this.ruleFormm.FarmesSoilAnalysisId = Number(this.ruleFormm.FarmesSoilAnalysisId);
                    

                    this.$blockUI.Start();
                    this.$http.EditSoilAnalysisInfo(this.ruleFormm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetSoilAnalysisInfo();
                            this.ClearForm(this.ruleFormm);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
     

        Delete(Id) {
            Swal.fire({
                title: 'تأكيد العملية ',
                text: 'هـل انت متأكد من عملية الحذف سيتم حدف جميع المجموعات و النخيل التابعين للمزرعة؟',
                icon: 'question',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false,
                confirmButtonText: `تأكيد العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteFarmes(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', 'حدت خطاء غير متوقع');
                            }
                        });
                    return;
                }
            });
        },
        DeleteGroup(Id) {
            Swal.fire({
                title: 'تأكيد العملية ',
                text: 'هـل انت متأكد من عملية الحذف سيتم حدف جميع النخيل في هده المجموعة؟',
                icon: 'question',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false,
                confirmButtonText: `تأكيد العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteGroup(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.ClearGroup();
                            this.GetGroup();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', 'حدت خطاء غير متوقع');
                            }
                        });
                    return;
                }
            });
        },

        DeleteSoilAnalysis(Id) {
            Swal.fire({
                title: 'تأكيد العملية ',
                text: 'هـل انت متأكد من عملية الحذف ؟',
                icon: 'question',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false,
                confirmButtonText: `تأكيد العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteSoilAnalysis(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetSoilAnalysis();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', 'حدت خطاء غير متوقع');
                            }
                        });
                    return;
                }
            });
        },


        //Suspends
      






        //InfoDialog
        OpentInfoDialog(item) {
            this.SelectedItem = item;
            this.ruleForm.Id = item.id;
            this.ruleForm.FirstName = item.firstName;
            this.ruleForm.FatherName = item.fatherName;
            this.ruleForm.SirName = item.sirName;
            this.ruleForm.Phone = item.phone;
            this.ruleForm.ExtraPhone = item.extraPhone;
            this.ruleForm.LoginName = item.loginName;
            this.ruleForm.Email = item.email;
            this.ruleForm.Descriptions = item.descriptions;
            this.ruleForm.AcademicLevelId = item.academicLevelId;
            this.ruleForm.AcademicSpecializationId = item.academicSpecializationId;
            this.GetCoursesInfo();
            //this.ruleForm.FirstName = item.firstName;
            //this.ruleForm.FatherName = item.fatherName;
            //this.ruleForm.SirName = item.sirName;
            //this.ruleForm.Phone = item.phone;
            //this.ruleForm.ExtraPhone = item.extraPhone;
            //this.ruleForm.LoginName = item.loginName;
            //this.ruleForm.Email = item.email;
            //this.ruleForm.Descriptions = item.descriptions;
            //this.ruleForm.AcademicLevelId = item.academicLevelId;
            //this.ruleForm.AcademicSpecializationId = item.academicSpecializationId;
            //this.GetCoursesInfo();
            this.state = 1;

        },

        GetWaterAnalysis(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetWaterAnalysis(this.pageNo, this.pageSize, this.ruleFormss.FarmesId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.WaterAnalysis = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetWaterAnalysisInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetWaterAnalysisInfo(this.pageNo, this.pageSize, this.ruleFormms.FarmesWaterAnalysisId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.WaterAnalysisInfo = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        OpenAddWaterAnalysisDialog(item) {
            this.SelectedItem = item;
            this.ruleFormss.FarmesId = item.id;
            this.ruleFormss.SampleType = '';
            this.ruleFormss.Service = '';
            this.ruleFormss.Company = '';
            this.ruleFormss.InvoiceDate = '';
            this.ruleFormss.InvoiceNumber = '';
            this.ruleFormss.Price = '';
            this.AddWaterAnalysisDialog = true;
            this.GetWaterAnalysis();

        },

        OpenAddWaterAnalysisInfoDialog(item) {
            this.SelectedItem = item;
            this.ruleFormms.FarmesWaterAnalysisId = item.id;
            this.ruleFormms.Type = '';
            this.ruleFormms.Parameters = '';
            this.ruleFormms.AnalysisMethods = '';
            this.ruleFormms.Units = '';
            this.ruleFormms.Results = '';
            this.ruleFormms.LibyanGroundwater = '';
            this.AddWaterAnalysisInfoDialog = true;
            this.GetWaterAnalysisInfo();
        },
        OpenAddGroupDialog(item) {
            this.SelectedItem = item;
            this.ruleFormGroup.FarmesId = item.id;
            this.GetGroup();
            this.ClearGroup();

            this.state = 1;

        },
        OpenEditGroupDialog(item) {
            this.SelectedItem = item;
            this.ruleFormGroup.Id = item.id;
            this.ruleFormGroup.Name = item.name;
            this.ruleFormGroup.CoordinateX = item.coordinateX;
            this.ruleFormGroup.CoordinateY = item.coordinateY;

            this.EditGropDialog = true;
            this.createTable();

        },

        submitWaterAnalysisForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleFormss.FarmesId = Number(this.ruleFormss.FarmesId);
                    this.ruleFormss.Id = Number(0);
                    this.ruleFormss.Price = Number(this.ruleFormss.Price);

                    this.$blockUI.Start();
                    this.$http.AddWaterAnalysis(this.ruleFormss)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetWaterAnalysis();

                            this.ClearForm(this.ruleFormss);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitWaterAnalysisInfo(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleFormms.FarmesWaterAnalysisId = Number(this.ruleFormms.FarmesWaterAnalysisId);
                    this.ruleFormms.Type = Number(this.ruleFormms.Type);
                    this.ruleFormms.Id = Number(0);


                    this.$blockUI.Start();
                    this.$http.AddWaterAnalysisInfo(this.ruleFormms)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetWaterAnalysisInfo();

                            this.ClearForm(this.ruleFormms);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitEditWaterAnalysisForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleFormss.Id = Number(this.ruleFormss.Id);
                    this.ruleFormss.FarmesId = Number(this.ruleFormss.FarmesId);
                    this.ruleFormss.Price = Number(this.ruleFormss.Price);

                    this.$blockUI.Start();
                    this.$http.EditGetWaterAnalysis(this.ruleFormss)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetWaterAnalysis();
                            this.ClearForm(this.ruleFormss);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitEditWaterAnalysisInfoForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleFormms.Id = Number(this.ruleFormms.Id);
                    this.ruleFormms.Type = Number(this.ruleFormms.Type);
                    this.ruleFormms.FarmesWaterAnalysisId = Number(this.ruleFormms.FarmesWaterAnalysisId);

                    this.$blockUI.Start();
                    this.$http.EditWaterAnalysisInfo(this.ruleFormms)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetWaterAnalysisInfo();
                            this.ClearForm(this.ruleFormms);
                            this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        ShowaWaterAnalysisDialog(item) {
            this.SelectedItem = item;
            this.ruleFormss.Id = item.id;
            this.ruleFormss.FarmesId = item.farmesId;
            this.ruleFormss.SampleType = item.sampleType;
            this.ruleFormss.Service = item.service;
            this.ruleFormss.Company = item.company;
            this.ruleFormss.InvoiceDate = item.invoiceDate;
            this.ruleFormss.InvoiceNumber = item.invoiceNumber;
            this.ruleFormss.Price = item.price;


        },

        ShowaWaterAnalysisInfoDialog(item) {
            this.SelectedItem = item;
            this.ruleFormms.Id = item.id;
            this.ruleFormms.Type = item.type;
            this.ruleFormms.FarmesWaterAnalysisId = item.farmesWaterAnalysisId;
            this.ruleFormms.Parameters = item.parameters;
            this.ruleFormms.AnalysisMethods = item.analysisMethods;
            this.ruleFormms.Units = item.units;
            this.ruleFormms.Results = item.results;
            this.ruleFormms.LibyanGroundwater = item.libyanGroundwater;


        },



        //Support Info
        Back() {
            this.state = 0;
            this.ClearForm(this.ruleForm);
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },

        DeleteWaterAnalysis(Id) {
            Swal.fire({
                title: 'تأكيد العملية ',
                text: 'هـل انت متأكد من عملية الحذف ؟',
                icon: 'question',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false,
                confirmButtonText: `تأكيد العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteWaterAnalysis(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetWaterAnalysis();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', 'حدت خطاء غير متوقع');
                            }
                        });
                    return;
                }
            });
        },


    }
}
