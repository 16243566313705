import axios from 'axios';

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {




    //********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/login`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },








    // ********************************| Start Dictionaries |******************************


    // Cities
    GetCities(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllCities() {
        return axios.get(`api/admin/Dictionaries/Cities/GetAll`);
    },

    AddCities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Cities/Add`, bodyObject);
    },

    EditCities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Cities/Edit`, bodyObject);
    },

    DeleteCities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Cities/Delete`);
    },

    ChangeStatusCities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Cities/ChangeStatus`);
    },
    // Farmes
    GetFarmes(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Farmes/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetSoilAnalysis(pageNo, pageSize, FarmesId) {
        return axios.get(`api/admin/Farmes/GetSoilAnalysis?pageno=${pageNo}&pagesize=${pageSize}&FarmesId=${FarmesId}`);
    },

    GetSoilAnalysisInfo(pageNo, pageSize, FarmesSoilAnalysisId) {
        return axios.get(`api/admin/Farmes/GetSoilAnalysisInfo?pageno=${pageNo}&pagesize=${pageSize}&FarmesSoilAnalysisId=${FarmesSoilAnalysisId}`);
    },

    GetWaterAnalysisInfo(pageNo, pageSize, FarmesWaterAnalysisId) {
        return axios.get(`api/admin/Farmes/GetWaterAnalysisInfo?pageno=${pageNo}&pagesize=${pageSize}&FarmesWaterAnalysisId=${FarmesWaterAnalysisId}`);
    },

    GetWaterAnalysis(pageNo, pageSize, FarmesId) {
        return axios.get(`api/admin/Farmes/GetWaterAnalysis?pageno=${pageNo}&pagesize=${pageSize}&FarmesId=${FarmesId}`);
    },
    GetGroup(pageNo, pageSize, FarmesId) {
        return axios.get(`api/admin/Farmes/GetGroup?pageno=${pageNo}&pagesize=${pageSize}&FarmesId=${FarmesId}`);
    },
    //GetAllCities() {
    //    return axios.get(`api/admin/Dictionaries/GetAll`);
    //},

    AddFarmes(bodyObject) {
        return axios.post(`api/admin/Farmes/Add`, bodyObject);
    },

    AddSoilAnalysis(bodyObject) {
        return axios.post(`api/admin/Farmes/AddSoilAnalysis`, bodyObject);
    },

    AddWaterAnalysis(bodyObject) {
        return axios.post(`api/admin/Farmes/AddWaterAnalysis`, bodyObject);
    },

    AddSoilAnalysisInfo(bodyObject) {
        return axios.post(`api/admin/Farmes/AddSoilAnalysisInfo`, bodyObject);
    },

    AddWaterAnalysisInfo(bodyObject) {
        return axios.post(`api/admin/Farmes/AddWaterAnalysisInfo`, bodyObject);
    },
    AddGroup(bodyObject) {
        return axios.post(`api/admin/Farmes/AddGroup`, bodyObject);
    },
    EditFarmes(bodyObject) {
        return axios.post(`api/admin/Farmes/Edit`, bodyObject);
    },
    EditGroup(bodyObject) {
        return axios.post(`api/admin/Farmes/EditGroup`, bodyObject);
    },
    EditSoilAnalysis(bodyObject) {
        return axios.post(`api/admin/Farmes/EditSoilAnalysis`, bodyObject);
    },
    EditSoilAnalysisInfo(bodyObject) {
        return axios.post(`api/admin/Farmes/EditSoilAnalysisInfo`, bodyObject);
    },
    EditGetWaterAnalysis(bodyObject) {
        return axios.post(`api/admin/Farmes/EditGetWaterAnalysis`, bodyObject);
    },

    EditWaterAnalysisInfo(bodyObject) {
        return axios.post(`api/admin/Farmes/EditWaterAnalysisInfo`, bodyObject);
    },
    DeleteFarmes(Id) {
        return axios.post(`api/admin/Farmes/${Id}/Delete`);
    },
    DeleteGroup(Id) {
        return axios.post(`api/admin/Farmes/${Id}/DeleteGroup`);
    },

    DeleteSoilAnalysis(Id) {
        return axios.post(`api/admin/Farmes/${Id}/DeleteSoilAnalysis`);
    },

    DeleteWaterAnalysis(Id) {
        return axios.post(`api/admin/Farmes/${Id}/DeleteWaterAnalysis`);
    },

    //ChangeStatusCities(Id) {
    //    return axios.post(`api/admin/Dictionaries/${Id}/Cities/ChangeStatus`);
    //},


    // Palms

    AddPalms(bodyObject) {
        return axios.post(`api/admin/Palms/Add`, bodyObject);
    },
    GetFarmesUsers(pageNo, pageSize) {
        return axios.get(`api/admin/Farmes/GetFarmesUsers?pageno=${pageNo}&pagesize=${pageSize}`);
    },


    //GetAllCities() {
    //    return axios.get(`api/admin/Dictionaries/GetAll`);
    //},

   

    Getallinfo(GroupsId, LocationsX, LocationsY) {
        return axios.get(`api/admin/Palms/Getallinfo?GroupsId=${GroupsId}&LocationsX=${LocationsX}&LocationsY=${LocationsY}`);
    },
    EditPalms(bodyObject) {
        return axios.post(`api/admin/Palms/Edit`, bodyObject);
    },
    DeletePalms(bodyObject) {
        return axios.post(`api/admin/Palms/Delete`, bodyObject);
    },


    // Fertilization
    GetPalmsFertilization(pageNo, pageSize, PalmsId) {
        return axios.get(`api/admin/Palms/Get/PalmsFertilization?pageno=${pageNo}&pagesize=${pageSize}&PalmsId=${PalmsId}`);
    },
    AddPalmsFertilization(bodyObject) {
        return axios.post(`api/admin/Palms/Add/PalmsFertilization`, bodyObject);
    },
    EditPalmsFertilization(bodyObject) {
        return axios.post(`api/admin/Palms/Edit/PalmsFertilization`, bodyObject);
    },
    DeletesPalmsFertilization(bodyObject) {
        return axios.post(`api/admin/Palms/Delete/PalmsFertilization`, bodyObject);
    },
    // Pests
    GetPalmsPests(pageNo, pageSize, PalmsId) {
        return axios.get(`api/admin/Palms/Get/PalmsPests?pageno=${pageNo}&pagesize=${pageSize}&PalmsId=${PalmsId}`);
    },
    AddPalmsPests(bodyObject) {
        return axios.post(`api/admin/Palms/Add/PalmsPests`, bodyObject);
    },
    EditPalmsPests(bodyObject) {
        return axios.post(`api/admin/Palms/Edit/PalmsPests`, bodyObject);
    },
    DeletesPalmsPests(bodyObject) {
        return axios.post(`api/admin/Palms/Delete/PalmsPests`, bodyObject);
    },
    // Production
    GetPalmsProduction(pageNo, pageSize, PalmsId) {
        return axios.get(`api/admin/Palms/Get/PalmsProduction?pageno=${pageNo}&pagesize=${pageSize}&PalmsId=${PalmsId}`);
    },
    AddPalmsProduction(bodyObject) {
        return axios.post(`api/admin/Palms/Add/PalmsProduction`, bodyObject);
    },
    EditPalmsProduction(bodyObject) {
        return axios.post(`api/admin/Palms/Edit/PalmsProduction`, bodyObject);
    },
    DeletesPalmsProduction(bodyObject) {
        return axios.post(`api/admin/Palms/Delete/PalmsProduction`, bodyObject);
    },
    // Care
    GetPalmsCare(pageNo, pageSize, PalmsId) {
        return axios.get(`api/admin/Palms/Get/PalmsCare?pageno=${pageNo}&pagesize=${pageSize}&PalmsId=${PalmsId}`);
    },
    AddPalmsCare(bodyObject) {
        return axios.post(`api/admin/Palms/Add/PalmsCare`, bodyObject);
    },
    EditPalmsCare(bodyObject) {
        return axios.post(`api/admin/Palms/Edit/PalmsCare`, bodyObject);
    },
    DeletesPalmsCare(bodyObject) {
        return axios.post(`api/admin/Palms/Delete/PalmsCare`, bodyObject);
    },
    // PreventiveOperations
    GetPalmsPreventiveOperations(pageNo, pageSize, PalmsId) {
        return axios.get(`api/admin/Palms/Get/PalmsPreventiveOperations?pageno=${pageNo}&pagesize=${pageSize}&PalmsId=${PalmsId}`);
    },
    AddPalmsPreventiveOperations(bodyObject) {
        return axios.post(`api/admin/Palms/Add/PalmsPreventiveOperations`, bodyObject);
    },
    EditPalmsPreventiveOperations(bodyObject) {
        return axios.post(`api/admin/Palms/Edit/PalmsPreventiveOperations`, bodyObject);
    },
    DeletesPalmsPreventiveOperations(bodyObject) {
        return axios.post(`api/admin/Palms/Delete/PalmsPreventiveOperations`, bodyObject);
    },
    // Seedlings
    GetPalmsSeedlings(pageNo, pageSize, PalmsId) {
        return axios.get(`api/admin/Palms/Get/PalmsSeedlings?pageno=${pageNo}&pagesize=${pageSize}&PalmsId=${PalmsId}`);
    },
    AddPalmsSeedlings(bodyObject) {
        return axios.post(`api/admin/Palms/Add/PalmsSeedlings`, bodyObject);
    },
    EditPalmsSeedlings(bodyObject) {
        return axios.post(`api/admin/Palms/Edit/PalmsSeedlings`, bodyObject);
    },
    DeletesPalmsSeedlings(bodyObject) {
        return axios.post(`api/admin/Palms/Delete/PalmsSeedlings`, bodyObject);
    },

    // Municipalities
    GetMunicipalities(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllMunicipalities(Id) {
        return axios.get(`api/admin/Dictionaries/Municipalities/GetAll?CitiesId=${Id}`);
    },

    AddMunicipalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Municipalities/Add`, bodyObject);
    },

    EditMunicipalities(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Municipalities/Edit`, bodyObject);
    },

    DeleteMunicipalities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Municipalities/Delete`);
    },

    ChangeStatusMunicipalities(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Municipalities/ChangeStatus`);
    },





    // FertilizationCompanies
    GetFertilizationCompanies(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/FertilizationCompanies/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllFertilizationCompanies() {
        return axios.get(`api/admin/Dictionaries/FertilizationCompanies/GetAll`);
    },

    AddFertilizationCompanies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/FertilizationCompanies/Add`, bodyObject);
    },

    EditFertilizationCompanies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/FertilizationCompanies/Edit`, bodyObject);
    },

    DeleteFertilizationCompanies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/FertilizationCompanies/Delete`);
    },

    ChangeStatusFertilizationCompanies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/FertilizationCompanies/ChangeStatus`);
    },




    // Fertilization
    GetFertilization(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Fertilization/Get?pageno=${pageNo}&pagesize=${pageSize}
        &Search=${Search}`);
    },

    GetAllFertilization(Id) {
        return axios.get(`api/admin/Dictionaries/Fertilization/GetAll?CompaniesId=${Id}`);
    },

    AddFertilization(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Fertilization/Add`, bodyObject);
    },

    EditFertilization(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Fertilization/Edit`, bodyObject);
    },

    DeleteFertilization(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Fertilization/Delete`);
    },

    ChangeStatusFertilization(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Fertilization/ChangeStatus`);
    },


    // PalmsType
    GetPalmsType(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/PalmsType/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllPalmsType() {
        return axios.get(`api/admin/Dictionaries/PalmsType/GetAll`);
    },

    AddPalmsType(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PalmsType/Add`, bodyObject);
    },

    EditPalmsType(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PalmsType/Edit`, bodyObject);
    },

    DeletePalmsType(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PalmsType/Delete`);
    },

    ChangeStatusPalmsType(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PalmsType/ChangeStatus`);
    },


    // PalmsCareType
    GetPalmsCareType(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/PalmsCareType/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllPalmsCareType() {
        return axios.get(`api/admin/Dictionaries/PalmsCareType/GetAll`);
    },

    AddPalmsCareType(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PalmsCareType/Add`, bodyObject);
    },

    EditPalmsCareType(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PalmsCareType/Edit`, bodyObject);
    },

    DeletePalmsCareType(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PalmsCareType/Delete`);
    },

    ChangeStatusPalmsCareType(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PalmsCareType/ChangeStatus`);
    },


    // PestsCompanies
    GetPestsCompanies(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/PestsCompanies/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllPestsCompanies() {
        return axios.get(`api/admin/Dictionaries/PestsCompanies/GetAll`);
    },

    AddPestsCompanies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PestsCompanies/Add`, bodyObject);
    },

    EditPestsCompanies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PestsCompanies/Edit`, bodyObject);
    },

    DeletePestsCompanies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PestsCompanies/Delete`);
    },

    ChangeStatusPestsCompanies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PestsCompanies/ChangeStatus`);
    },




    // Pests
    GetPests(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Pests/Get?pageno=${pageNo}&pagesize=${pageSize}
        &Search=${Search}`);
    },
    GetPestsAll() {
        return axios.get(`api/admin/Dictionaries/Pests/GetPestsAll`);
    },
    GetAllPests(Id) {
        return axios.get(`api/admin/Dictionaries/Pests/GetAll?CompaniesId=${Id}`);
    },

    AddPests(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Pests/Add`, bodyObject);
    },

    EditPests(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Pests/Edit`, bodyObject);
    },

    DeletePests(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Pests/Delete`);
    },

    ChangeStatusPests(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Pests/ChangeStatus`);
    },



    // ProductionsClassification
    GetProductionsClassification(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/ProductionsClassification/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllProductionsClassification() {
        return axios.get(`api/admin/Dictionaries/ProductionsClassification/GetAll`);
    },

    AddProductionsClassification(bodyObject) {
        return axios.post(`api/admin/Dictionaries/ProductionsClassification/Add`, bodyObject);
    },

    EditProductionsClassification(bodyObject) {
        return axios.post(`api/admin/Dictionaries/ProductionsClassification/Edit`, bodyObject);
    },

    DeleteProductionsClassification(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/ProductionsClassification/Delete`);
    },

    ChangeStatusProductionsClassification(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/ProductionsClassification/ChangeStatus`);
    },



    // PesticidesCompanies
    GetPesticidesCompanies(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/PesticidesCompanies/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllPesticidesCompanies() {
        return axios.get(`api/admin/Dictionaries/PesticidesCompanies/GetAll`);
    },

    AddPesticidesCompanies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PesticidesCompanies/Add`, bodyObject);
    },

    EditPesticidesCompanies(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PesticidesCompanies/Edit`, bodyObject);
    },

    DeletePesticidesCompanies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PesticidesCompanies/Delete`);
    },

    ChangeStatusPesticidesCompanies(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PesticidesCompanies/ChangeStatus`);
    },



    // Pesticides
    GetPesticides(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Pesticides/Get?pageno=${pageNo}&pagesize=${pageSize}
        &Search=${Search}`);
    },

    GetAllPesticides(Id) {
        return axios.get(`api/admin/Dictionaries/Pesticides/GetAll?CompaniesId=${Id}`);
    },

    AddPesticides(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Pesticides/Add`, bodyObject);
    },

    EditPesticides(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Pesticides/Edit`, bodyObject);
    },

    DeletePesticides(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Pesticides/Delete`);
    },

    ChangeStatusPesticides(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Pesticides/ChangeStatus`);
    },













    GetDashboardInfo() {
        return axios.get(`api/admin/Dictionaries/Dashboard/Get`);
    },



    // ********************************| End Dictionaries |********************************
























    // ********************************| Courses |************************************

    GetCourses(pageNo, pageSize, Search, IsFree, IsDiscount, SalesStatus, ViewStatus) {
        return axios.get(`api/admin/Courses/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
            &IsFree=${IsFree}&IsDiscount=${IsDiscount}&SalesStatus=${SalesStatus}&Status=${ViewStatus}`);
    },

    GetAllCourses() {
        return axios.get(`api/admin/Courses/GetAll`);
    },

    GetAllCoursesByMunicipalitiespecialization(SubjectId, AcademicSpecializationId) {
        return axios.get(`api/admin/Courses/GetByMunicipalitiespecialization?SubjectId=${SubjectId}&AcademicSpecializationId=${AcademicSpecializationId}`);
    },

    AddCourses(bodyObject) {
        return axios.post(`api/admin/Courses/Add`, bodyObject);
    },

    EditCourses(bodyObject) {
        return axios.post(`api/admin/Courses/Edit`, bodyObject);
    },

    DeleteCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/Delete`);
    },

    ChangeStatusCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/ChangeStatus`);
    },

    ChangeSalesStatusCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/ChangeSalesStatus`);
    },

    CloseCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/Close`);
    },




    //Closed Course
    GetClosedCourses(pageNo, pageSize, Search, IsFree, IsDiscount) {
        return axios.get(`api/admin/Courses/GetClosed?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
            &IsFree=${IsFree}&IsDiscount=${IsDiscount}`);
    },

    OpenCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/Open`);
    },


    //ChartInfo
    GetAllCoursesChartInfo() {
        return axios.get(`api/admin/Courses/Chart/GetAll`);
    },


    //ChartInfo
    GetCoursesChartInfo(Id) {
        return axios.get(`api/admin/Courses/Chart/Get?Id=${Id}`);
    },


   





    //Shapters
    GetCourseShapters(Id, Search) {
        return axios.get(`api/admin/Courses/Shapters/Get?Id=${Id}&Search=${Search}`);
    },

    GetAllShapters(Id) {
        return axios.get(`api/admin/Courses/Shapters/GetAll?CourseId=${Id}`);
    },

    AddShapters(bodyObject) {
        return axios.post(`api/admin/Courses/Shapters/Add`, bodyObject);
    },

    EditShapters(bodyObject) {
        return axios.post(`api/admin/Courses/Shapters/Edit`, bodyObject);
    },

    DeleteShapters(Id) {
        return axios.post(`api/admin/Courses/${Id}/Shapters/Delete`);
    },

    ChangeStatusShapters(Id) {
        return axios.post(`api/admin/Courses/${Id}/Shapters/ChangeStatus`);
    },






    //Lectures
    GetCourseLectures(CourseId,ShapterId, Search) {
        return axios.get(`api/admin/Courses/Lectures/Get?CourseId=${CourseId}&ShapterId=${ShapterId}&Search=${Search}`);
    },

    AddLectures(bodyObject) {
        return axios.post(`api/admin/Courses/Lectures/Add`, bodyObject);
    },

    EditLectures(bodyObject) {
        return axios.post(`api/admin/Courses/Lectures/Edit`, bodyObject);
    },

    DeleteLectures(Id) {
        return axios.post(`api/admin/Courses/${Id}/Lectures/Delete`);
    },

    ChangeStatusLectures(Id) {
        return axios.post(`api/admin/Courses/${Id}/Lectures/ChangeStatus`);
    },






    //LecturesAttashments
    GetCourseLecturesAttashments(LectureId, Search) {
        return axios.get(`api/admin/Courses/LecturesAttashments/Get?LectureId=${LectureId}&Search=${Search}`);
    },

    AddLecturesAttashments(bodyObject) {
        return axios.post(`api/admin/Courses/LecturesAttashments/Add`, bodyObject);
    },

    EditLecturesAttashments(bodyObject) {
        return axios.post(`api/admin/Courses/LecturesAttashments/Edit`, bodyObject);
    },

    DeleteLecturesAttashments(Id) {
        return axios.post(`api/admin/Courses/${Id}/LecturesAttashments/Delete`);
    },

    ChangeStatusLecturesAttashments(Id) {
        return axios.post(`api/admin/Courses/${Id}/LecturesAttashments/ChangeStatus`);
    },






    //Exams
    GetCourseExams(CourseId,ShapterId, Search) {
        return axios.get(`api/admin/Courses/Exams/Get?CourseId=${CourseId}&ShapterId=${ShapterId}&Search=${Search}`);
    },

    AddExams(bodyObject) {
        return axios.post(`api/admin/Courses/Exams/Add`, bodyObject);
    },

    EditExams(bodyObject) {
        return axios.post(`api/admin/Courses/Exams/Edit`, bodyObject);
    },

    DeleteExams(Id) {
        return axios.post(`api/admin/Courses/${Id}/Exams/Delete`);
    },

    ChangeStatusExams(Id) {
        return axios.post(`api/admin/Courses/${Id}/Exams/ChangeStatus`);
    },






    //ExamsQuestions
    GetCourseExamsQuestions(ExamId, Search) {
        return axios.get(`api/admin/Courses/ExamsQuestions/Get?ExamId=${ExamId}&Search=${Search}`);
    },

    AddExamsQuestions(bodyObject) {
        return axios.post(`api/admin/Courses/ExamsQuestions/Add`, bodyObject);
    },

    EditExamsQuestions(bodyObject) {
        return axios.post(`api/admin/Courses/ExamsQuestions/Edit`, bodyObject);
    },

    DeleteExamsQuestions(Id) {
        return axios.post(`api/admin/Courses/${Id}/ExamsQuestions/Delete`);
    },

    ChangeStatusExamsQuestions(Id) {
        return axios.post(`api/admin/Courses/${Id}/ExamsQuestions/ChangeStatus`);
    },


    //Students
    GetCourseStudents(Id, Search) {
        return axios.get(`api/admin/Courses/Students/Get?Id=${Id}&Search=${Search}`);
    },


    //Review
    GetCourseReview(Id, Search) {
        return axios.get(`api/admin/Courses/Review/Get?Id=${Id}&Search=${Search}`);
    },





    //// ********************************| End Courses |********************************















    //// ********************************| Students |********************************


    GetStudents(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    AddStudents(bodyObject) {
        return axios.post(`api/admin/Students/Add`, bodyObject);
    },

    EditStudents(bodyObject) {
        return axios.post(`api/admin/Students/Edit`, bodyObject);
    },

    ChangeStatusStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeStatus`);
    },

    ResetDeviceStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/ResetDevice`);
    },

    ResetPasswordStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/ResetPassword`);
    },

    DeleteStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/Delete`);
    },

    //Suspends
    SuspendsStudents(bodyObject) {
        return axios.post(`api/admin/Students/Suspends/Add`, bodyObject);
    },

    GetSuspendsStudents(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/Suspends/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    CanselSuspends(Id) {
        return axios.post(`api/admin/Students/${Id}/Suspends/Cansel`);
    },





    //ChangeRequest
    GetChangeRequest(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/ChangeRequest/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    AcceptChangeRequest(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeRequest/Accept`);
    },

    AcceptAllChangeRequest() {
        return axios.post(`api/admin/Students/ChangeRequest/AcceptAll`);
    },

    RejectChangeRequest(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeRequest/Reject`);
    },

    DeleteChangeRequest(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeRequest/Delete`);
    },

    DeleteAllChangeRequest() {
        return axios.post(`api/admin/Students/ChangeRequest/DeleteAll`);
    },





    //CoursesRequests
    GetCoursesRequests(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/CoursesRequests/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    AcceptCoursesRequests(Id) {
        return axios.post(`api/admin/Students/${Id}/CoursesRequests/Accept`);
    },

    RejectCoursesRequests(Id) {
        return axios.post(`api/admin/Students/${Id}/CoursesRequests/Reject`);
    },

    DeleteCoursesRequests(Id) {
        return axios.post(`api/admin/Students/${Id}/CoursesRequests/Delete`);
    },

    







    //Studetns Courses
    GetStudentsCourses(Id, Search) {
        return axios.get(`api/admin/Students/Courses/Get?Id=${Id}&Search=${Search}`);
    },

    AddStudentsCourses(bodyObject) {
        return axios.post(`api/admin/Students/Courses/Add`, bodyObject);
    },

    AddStudentsCoursesValue(bodyObject) {
        return axios.post(`api/admin/Students/Courses/AddValue`, bodyObject);
    },

    DropStudentsCourses(bodyObject) {
        return axios.post(`api/admin/Students/Courses/Drop`, bodyObject);
    },







    //Wallet
    GetStudentsWallet(Id) {
        return axios.get(`api/admin/Students/Wallet/Get?Id=${Id}`);
    },

    AddStudentsWalletValue(bodyObject) {
        return axios.post(`api/admin/Students/Wallet/Charge`, bodyObject);
    },






    //Devices
    GetStudentsDevices(Id) {
        return axios.get(`api/admin/Students/Devices/Get?Id=${Id}`);
    },


    GetAllStudentsChartInfo() {
        return axios.get(`api/admin/Students/Chart/GetAll`);
    },



    //// ********************************| End Of Students |********************************













    // ********************************| Instructors |********************************



    GetInstructors(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Instructors/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllInstructors() {
        return axios.get(`api/admin/Instructors/GetAll`);
    },

    AddInstructors(bodyObject) {
        return axios.post(`api/admin/Instructors/Add`, bodyObject);
    },

    EditInstructors(bodyObject) {
        return axios.post(`api/admin/Instructors/Edit`, bodyObject);
    },

    ChangeStatusInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/ChangeStatus`);
    },

    ResetDeviceInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/ResetDevice`);
    },

    ResetPasswordInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/ResetPassword`);
    },

    DeleteInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/Delete`);
    },

    //Suspends
    SuspendsInstructors(bodyObject) {
        return axios.post(`api/admin/Instructors/Suspends/Add`, bodyObject);
    },

    GetSuspendsInstructors(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Instructors/Suspends/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    CanselInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/Suspends/Cansel`);
    },



    // ********************************| End Of Instructors |********************************












    // ********************************| Financial |********************************

    GetSubscriptions(pageNo, pageSize, Search, From, To) {
        return axios.get(`api/admin/Financial/GetSubscriptions?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
        &From=${From}&To=${To}`);
    },

    GetRecharge(pageNo, pageSize, Search, From, To, UserId, DistributorsId, PaymentMethodId) {
        return axios.get(`api/admin/Financial/GetRecharge?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
        &From=${From}&To=${To}&UserId=${UserId}&DistributorsId=${DistributorsId}&PaymentMethodId=${PaymentMethodId}`);
    },

    GetWalletTracker(pageNo, pageSize, Search, From, To, ProcessType, Channel) {
        return axios.get(`api/admin/Financial/GetWalletTracker?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
        &From=${From}&To=${To}&ProcessType=${ProcessType}&Channel=${Channel}`);
    },





    // ********************************| End Of Financial |********************************














    // ********************************| VoucherCards |********************************

    GetVoucherCards(pageNo, pageSize, Search, DistributorsId) {
        return axios.get(`api/admin/VoucherCards/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}&DistributorsId=${DistributorsId}`);
    },

    AddVoucherCards(bodyObject) {
        return axios.post('api/admin/VoucherCards/Add', bodyObject);
    },

    SaleVoucherCards(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Sale`, { responseType: 'blob' });
    },

    ForzePackage(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Freeze`, { responseType: 'blob' });
    },

    DeletePackage(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Delete`);
    },



    //Card
    GetCards() {
        return axios.get(`api/admin/VoucherCards/Cards/Get`);
    },

    GetCardInfoBySerialNumber(SerialNumber) {
        return axios.get(`api/admin/VoucherCards/Cards/GetCardInfoBySerialNumber?SerialNumber=${SerialNumber}`);
    },

    GetCardInfoByVoucherNumber(VoucherNumber) {
        return axios.get(`api/admin/VoucherCards/Cards/GetCardInfoByVoucherNumber?SerialNumber=${VoucherNumber}`);
    },


    //TryAttemp
    GetVoucherCardsTryAttemp(pageNo, pageSize, Search) {
        return axios.get(`api/admin/VoucherCards/TryAttemp/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },



    //Chart
    GetVoucherCardsChart() {
        return axios.get(`api/admin/VoucherCards/Chart/Get`);
    },



    


    //Distributors
    GetDistributors(pageNo, pageSize, Search) {
        return axios.get(`api/admin/VoucherCards/Distributors/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllDistributors() {
        return axios.get(`api/admin/VoucherCards/Distributors/GetAll`);
    },

    AddDistributors(bodyObject) {
        return axios.post('api/admin/VoucherCards/Distributors/Add', bodyObject);
    },

    EditDistributors(bodyObject) {
        return axios.post('api/admin/VoucherCards/Distributors/Edit', bodyObject);
    },

    ResetPasswordDistributors(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Distributors/ResetPassword`);
    },

    ChangeStatusDistributors(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Distributors/ChangeStatus`);
    },

    DeleteDistributors(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Distributors/Delete`);
    },




    ActivePackgeDistributors(bodyObject) {
        return axios.post('api/admin/VoucherCards/Distributors/ActivePackge', bodyObject);
    },

    GetDistributorsPackages(pageNo, pageSize) {
        return axios.get(`api/admin/VoucherCards/Distributors/Packages/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    // ********************************| End Of VoucherCards |********************************












    // ********************************| Users |********************************
    GetUsers(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Users/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetUsersTransactions(pageNoT, pageSizeT, Id) {
        return axios.get(`api/admin/Users/GetTransactions?pageNo=${pageNoT}&pagesize=${pageSizeT}&Id=${Id}`);
    },

    GetAllUsers() {
        return axios.get(`api/admin/Users/GetAll`);
    },

    AddUsers(bodyObject) {
        return axios.post('api/admin/Users/Add', bodyObject);
    },

    EditUsers(bodyObject) {
        return axios.post('api/admin/Users/Edit', bodyObject);
    },

    ResetPasswordUsers(Id) {
        return axios.post(`api/admin/Users/${Id}/ResetPassword`);
    },

    ChangeStatusUsers(Id) {
        return axios.post(`api/admin/Users/${Id}/ChangeStatus`);
    },

    DeleteUsers(Id) {
        return axios.post(`api/admin/Users/${Id}/Delete`);
    },


    //USerProfile 
    ChangeProfilePicture(bodyObject) {
        return axios.post('api/admin/Users/Profile/ChangePicture', bodyObject);
    },

    ChangeProfileInfo(bodyObject) {
        return axios.post('api/admin/Users/Profile/ChangeInfo', bodyObject);
    },

    ChangeProfilePassword(bodyObject) {
        return axios.post('api/admin/Users/Profile/ChangePassword', bodyObject);
    },




    // ********************************| End Of Users |********************************




    
    
}