import Swal from "sweetalert2";
import HelperMixin from '../../Shared/HelperMixin.vue';
import PaginationHelper from '../../Shared/PaginationHelper.vue';
export default {
    name: 'Courses',
    mixins: [HelperMixin],
    components: {
        PaginationHelper
    },

    async created() {
        await this.CheckLoginStatus();
        await this.GetAllPalmsType()
        await this.GetAllFertilizationCompanies()
        await this.GetPests()
        await this.GetAllPesticidesCompanies()
        await this.GetAllProductionsClassification()
        await this.GetAllPalmsCareType()
        this.GetInfo1();
        this.GetInfoPests();
        this.createTable1();

        /*await this.GetAllMunicipalities()*/

        this.GetInfo();
    },

    computed: {
        totalPages() {
            return Math.ceil(this.pages / this.pageSize);
        }
    },

    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Search: '',

            ScreenTitle: ' النخيل  ',
            ScreenTitleSingle: ' نخلة ',
            ScreenTitleSingleAl: 'النخلة ',

            Info: [],
            Cities: [],
            FertilizationCompanies: [],
            PalmsFertilization: [],
            PalmsPests: [],
            PalmsProduction: [],
            PalmsCare: [],
            PalmsPreventiveOperations: [],
            PalmsSeedlings: [],

            Statistics: [],
            StatisticsPalms: [],

            AddPalmDialog: false,
            SelectedItem: '',
            ruleForm: {
                Id: '',
                PalmsTypeId: '',
                Classification: '',
                FarmedOn: '',
                ExpectedProduction: '',
                GroupsId: '',
                FarmesId: '',
                LocationsX: '',
                LocationsY: '',

            },
            FertilizationForm: {
                Id: '',
                PalmsId: '',
                ParentId: '',
                FertilizationId: '',
                FertilizationDate: '',
                Quantity: '',
                Results: '',


            },
            PestsForm: {
                Id: '',
                PalmsId: '',
                ParentId: '',
                PestsId: '',
                PesticidesId: '',
                PestsDate: '',
                Quantity: '',
                Results: '',


            },
            ProductionForm: {
                Id: '',
                PalmsId: '',
                Quantity: '',
                ClassificationId: '',
                ProductionDate: '',
                Price: '',


            },
            CareForm: {
                Id: '',
                PalmsId: '',
                TypeId: '',
                Date: '',
            },
            PreventiveOperationsForm: {
                Id: '',
                PalmsId: '',
                ParentId: '',
                PesticidesId: '',
                DateSpraying: '',
                Results: '',
                Descriptions: '',
            },
            SeedlingsForm: {
                Id: '',
                PalmsId: '',
                Number: '',
                StartDate: '',
                DateCut: '',

            },

            rules: {
                PalmsTypeId: this.$helper.Required(),
                Classification: this.$helper.Required(),
                FarmedOn: this.$helper.Required(),
                ExpectedProduction: this.$helper.Required(),


                FertilizationId: this.$helper.Required(),
                FertilizationDate: this.$helper.Required(),
                Quantity: this.$helper.Required(),
                Results: this.$helper.Required(),

                PestsId: this.$helper.Required(),
                PesticidesId: this.$helper.Required(),
                PestsDate: this.$helper.Required(),

                ClassificationId: this.$helper.Required(),
                ProductionDate: this.$helper.Required(),
                Price: this.$helper.Required(),

                TypeId: this.$helper.Required(),
                Date: this.$helper.Required(),
                DateSpraying: this.$helper.Required(),
                Number: this.$helper.Required(),
                StartDate: this.$helper.Required(),
                DateCut: this.$helper.Required(),


            },




        };
    },



    methods: {
        async GetAllFertilizations() {
            this.GetAllFertilization(this.FertilizationForm.ParentId)
        },
        async GetAllPesticidess() {
            this.GetAllPesticides(this.PestsForm.ParentId)
        },
        async GetAllPesticidesOperations() {
            this.GetAllPesticides(this.PreventiveOperationsForm.ParentId)
        },
        Clear() {
            this.ruleForm.PalmsTypeId = '';
            this.ruleForm.Classification = '';
            this.ruleForm.FarmedOn = '';
            this.ruleForm.ExpectedProduction = '';
            this.ruleForm.Id = '';

        },
        Clear2() {
            this.ruleForm.PalmsTypeId = '';
            this.ruleForm.Classification = '';
            this.ruleForm.FarmedOn = '';
            this.ruleForm.ExpectedProduction = '';
            this.ruleForm.LocationsX = '';
            this.ruleForm.LocationsY = '';
            this.ruleForm.Id = '';

        },


        Getallinfo1() {

            this.PalmsTypeId = '';
            this.PalmsTypeId = [];
            this.Classification = '';
            this.Classification = [];
            this.FarmedOn = '';
            this.FarmedOn = [];
            this.ExpectedProduction = '';
            this.ExpectedProduction = [];
            this.$blockUI.Start();
            this.$http.Getallinfo(this.ruleForm.GroupsId, this.ruleForm.LocationsX, this.ruleForm.LocationsY)
                .then(response => {
                    this.$blockUI.Stop();
                    this.ruleForm.Id = response.data.info.id;
                    this.ruleForm.GroupsId = response.data.info.groupsId;
                    this.ruleForm.PalmsTypeId = response.data.info.palmsTypeId;
                    this.ruleForm.Classification = response.data.info.classification;
                    this.ruleForm.FarmedOn = response.data.info.farmedOn;
                    this.ruleForm.ExpectedProduction = response.data.info.expectedProduction;
                    //this.ruleForm.Activ = response.data.info[0].activ;

                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },

        createTable() {
            const rows = this.ruleForm.CoordinateY;
            const cols = this.ruleForm.CoordinateX;
            const farmDiv = document.getElementById('farm');

            // مسح المحتوى السابق
            farmDiv.innerHTML = '';

            // إنشاء الجدول
            const table = document.createElement('table');
            for (let i = 0; i < rows; i++) {
                const row = document.createElement('tr');
                for (let j = 0; j < cols; j++) {
                    const cell = document.createElement('td');
                    // إضافة النخيل (رمز) في الخلايا
                    cell.innerHTML = '🌴';
                    cell.className = 'palm'; // تعيين الصنف

                    // إضافة حدث click للخلية
                    cell.addEventListener('click', () => {
                        this.Clear();
                        this.openAddPalmDialog(i, j);
                        this.Getallinfo1();
                    });

                    row.appendChild(cell);
                }
                table.appendChild(row);
            }
            farmDiv.appendChild(table);
        },



//        createTable1() {
//            const farmDiv = document.getElementById('farm1');

//            // مسح المحتوى السابق
//            farmDiv.innerHTML = '';

//            // جلب بيانات النخيل من الخادم
//            const farmId = this.ruleForm.FarmesId;

//            fetch(`api/admin/Palms/GetPalms/${farmId}`)
//                .then(response => {
//                    if (!response.ok) {
//                        throw new Error('فشل في جلب البيانات');
//                    }
//                    return response.json();
//                })
//                .then(data => {
//                    if (data.length === 0) {
//                        farmDiv.innerHTML = '<p>لا توجد بيانات للنخيل في هذه المزرعة.</p>';
//                        return;
//                    }

//                    const minY = Math.min(...data.map(palm => palm.locationsY));
//                    const maxY = Math.max(...data.map(palm => palm.locationsY));
//                    const minX = Math.min(...data.map(palm => palm.locationsX));
//                    const maxX = Math.max(...data.map(palm => palm.locationsX));

//                    const table = document.createElement('table');
//                    table.style.borderCollapse = 'collapse';
//                    table.style.marginTop = '20px';

//                    for (let i = minY; i <= maxY; i++) {
//                        const row = document.createElement('tr');

//                        for (let j = minX; j <= maxX; j++) {
//                            const palm = data.find(p => p.locationsX === j && p.locationsY === i);
//                            const cell = document.createElement('td');
//                            cell.style.width = '50px';
//                            cell.style.height = '50px';
//                            cell.style.textAlign = 'center';
//                            cell.style.border = '1px solid #ccc';
//                            cell.style.cursor = 'pointer';
//                            cell.style.position = 'relative'; // لجعل القائمة المنسدلة تظهر في المكان الصحيح

//                            if (palm) {
//                                // تغيير لون الخلية إذا كانت النخلة فارغة
//                                if (palm.isEmpty === 2) {
//                                    cell.style.backgroundColor = '#00FF00'; // لون للخلايا الفارغة
//                                }

//                                // إنشاء حاوية لتنسيق الأيقونات
//                                const iconContainer = document.createElement('div');
//                                iconContainer.style.display = 'flex'; // استخدام Flexbox
//                                iconContainer.style.flexDirection = 'row'; // جعل العناصر تتجه أفقيًا
//                                iconContainer.style.alignItems = 'center'; // محاذاة العناصر في المنتصف
//                                iconContainer.style.position = 'relative'; // لجعل القائمة المنسدلة تظهر بشكل صحيح

//                                // إنشاء السهم للقائمة المنسدلة
//                                const palmIcon = document.createElement('span');
//                                palmIcon.innerHTML = '🌴'; // رمز النخلة
//                                palmIcon.style.fontSize = '20px'; // حجم النخلة
//                                palmIcon.style.marginRight = '13px'; // مسافة بين السهم والنخلة

//                                const dropdownToggle = document.createElement('span');
//                                dropdownToggle.innerHTML = '🛠️'; // رمز علامة الإعدادات (التروس)
//                                dropdownToggle.style.cursor = 'pointer';
//                                dropdownToggle.style.fontSize = '20px'; // حجم السهم
//                                dropdownToggle.style.marginLeft = '13px'; // زيادة المسافة بين الأيقونة والسهم
//                                dropdownToggle.style.width = '13px'; // زيادة المسافة بين الأيقونة والسهم


//                                // إضافة رمز النخلة


//                                // إنشاء قائمة منسدلة
//                                const dropdown = document.createElement('div');
//                                dropdown.style.display = 'none'; // إخفاء القائمة في البداية
//                                dropdown.style.position = 'absolute'; // لجعل القائمة منسدلة
//                                dropdown.style.backgroundColor = '#fff';
//                                dropdown.style.border = '1px solid #ccc';
//                                dropdown.style.zIndex = '1000';
//                                dropdown.style.left = '50%'; // محاذاة القائمة
//                                dropdown.style.transform = 'translateX(-50%)'; // محاذاة القائمة
//                                dropdown.style.marginTop = '5px'; // المسافة بين السهم والقائمة
//                                dropdown.style.padding = '5px'; // إضافة مساحة داخلية للقائمة

//                                // إضافة خيارات للقائمة
//                                dropdown.innerHTML = `
//    <ul class="dropdown-list" style="list-style: none; padding: 6px; margin: 0;">
//   <li>
//    <a href="javascript:;"
//       @click.prevent="this.OpentFertilizationDialog(palm)"
//       type="button"
//       title="التسميد"
//       class="btn btn-icon btn-text-info waves-effect waves-light rounded-pill"
//       data-bs-placement="top"
//       aria-label="AddFertilization"
//       data-bs-target="#AddFertilization"
//       data-bs-toggle="modal"
//       data-bs-original-title="secondary"
//       style="display: flex; align-items: center; width: 150px; padding: 10px;">
//        <i class="fas fa-seedling" style="font-size: 1.5em; margin-right: 10px;"></i>
//        <span class="icon-text">التسميد</span>
//    </a>
//</li>
//<li>
//    <a href="javascript:;"
//       @click.prevent="this.OpentPestsDialog(palm)"
//       type="button"
//       title="الآفات"
//       class="btn btn-icon btn-text-danger waves-effect waves-light rounded-pill"
//       data-bs-placement="top"
//       aria-label="AddPests"
//       data-bs-target="#AddPests"
//       data-bs-toggle="modal"
//       data-bs-original-title="secondary"
//       style="display: flex; align-items: center; width: 150px; padding: 10px;">
//        <i class="fas fa-exclamation-triangle" style="font-size: 1.5em; margin-right: 10px;"></i>
//        <span class="icon-text">الآفات</span>
//    </a>
//</li>
//<li>
//    <a href="javascript:;"
//       @click.prevent="this.OpentProductionDialog(palm)"
//       type="button"
//       title="الانتاج"
//       class="btn btn-icon btn-text-success waves-effect waves-light rounded-pill"
//       data-bs-placement="top"
//       aria-label="AddProduction"
//       data-bs-target="#AddProduction"
//       data-bs-toggle="modal"
//       data-bs-original-title="secondary"
//       style="display: flex; align-items: center; width: 150px; padding: 10px;">
//        <i class="fas fa-apple-alt" style="font-size: 1.5em; margin-right: 10px;"></i>
//        <span class="icon-text">الانتاج</span>
//    </a>
//</li>
//<li>
//    <a href="javascript:;"
//       @click.prevent="this.OpentCareDialog(palm)"
//       type="button"
//       title="العناية"
//       class="btn btn-icon btn-text-secondary waves-effect waves-light rounded-pill"
//       data-bs-placement="top"
//       aria-label="AddCare"
//       data-bs-target="#AddCare"
//       data-bs-toggle="modal"
//       data-bs-original-title="secondary"
//       style="display: flex; align-items: center; width: 150px; padding: 10px;">
//        <i class="fas fa-hand-holding-heart" style="font-size: 1.5em; margin-right: 10px;"></i>
//        <span class="icon-text">العناية</span>
//    </a>
//</li>
//<li>
//    <a href="javascript:;"
//       @click.prevent="this.OpentPreventiveOperationsDialog(palm)"
//       type="button"
//       title="العمليات الوقائية"
//       class="btn btn-icon btn-text-info waves-effect waves-light rounded-pill"
//       data-bs-placement="top"
//       aria-label="AddPreventiveOperations"
//       data-bs-target="#AddPreventiveOperations"
//       data-bs-toggle="modal"
//       data-bs-original-title="secondary"
//       style="display: flex; align-items: center; width: 170px; padding: 10px;">
//        <i class="fas fa-shield-alt" style="font-size: 1.5em; margin-right: 10px;"></i>
//        <span class="icon-text">العمليات الوقائية</span>
//    </a>
//</li>
//<li>
//    <a href="javascript:;"
//       @click.prevent="this.OpentSeedlingsDialog(palm)"
//       type="button"
//       title="انتاج الفسائل"
//       class="btn btn-icon btn-text-success waves-effect waves-light rounded-pill"
//       data-bs-placement="top"
//       aria-label="AddSeedlings"
//       data-bs-target="#AddSeedlings"
//       data-bs-toggle="modal"
//       data-bs-original-title="secondary"
//       style="display: flex; align-items: center; width: 150px; padding: 10px;">
//        <i class="fas fa-tree" style="font-size: 1.5em; margin-right: 10px;"></i>
//        <span class="icon-text">انتاج الفسائل</span>
//    </a>
//</li>
//    </ul>
//`;

//                                // إضافة الأيقونات إلى الحاوية
//                                iconContainer.appendChild(dropdownToggle); // أضف السهم أولاً
//                                iconContainer.appendChild(palmIcon); // ثم أضف الأيقونة
//                                cell.appendChild(iconContainer); // إضافة الحاوية إلى الخلية
//                                cell.appendChild(dropdown); // إضافة القائمة المنسدلة إلى الخلية

//                                // إظهار أو إخفاء القائمة عند النقر على السهم
//                                if (palm.isEmpty === 2) {
//                                    dropdownToggle.addEventListener('click', (event) => {
//                                        event.stopPropagation(); // منع الحدث من الانتقال إلى العنصر الأب
//                                        const isVisible = dropdown.style.display === 'block';
//                                        dropdown.style.display = isVisible ? 'none' : 'block'; // التبديل بين إظهار وإخفاء القائمة
//                                    });
//                                }
//                                // عند النقر على الخلية (النخلة)، لا تغلق القائمة
//                                cell.addEventListener('click', (event) => {
//                                    event.stopPropagation(); // منع إغلاق القائمة المنسدلة
//                                    this.openAddPalmDialog(i, j); // زيادة 1 للإحداثيات
//                                    this.Getallinfo1();
//                                    this.FertilizationFX(palm);
//                                    this.PestsFX(palm);
//                                    this.ProductionsFX(palm);
//                                    this.CareFX(palm);
//                                    this.PreventiveOperationsFX(palm);
//                                    this.SeedlingsFX(palm);

//                                });
//                            }

//                            row.appendChild(cell);
//                        }

//                        table.appendChild(row);
//                    }

//                    farmDiv.appendChild(table);
//                })
//                .catch(error => {
//                    farmDiv.innerHTML = `<p style="color:red;">${error.message}</p>`;
//                });

//            // إخفاء القوائم المنسدلة عند النقر في أي مكان آخر
//            document.addEventListener('click', (event) => {
//                // تحقق مما إذا كان النقر داخل أي قائمة منسدلة
//                const target = event.target;
//                const dropdowns = farmDiv.querySelectorAll('div[style*="position: absolute;"]');

//                dropdowns.forEach(dropdown => {
//                    // إذا كان النقر داخل القائمة، فلا تفعل شيئًا
//                    if (dropdown.contains(target)) {
//                        return;
//                    }
//                    // إخفاء القائمة إذا لم يكن النقر داخلها
//                    dropdown.style.display = 'none';
//                });
//            });
//        },
        createTable1() {
            const farmDiv = document.getElementById('farm1');

            // مسح المحتوى السابق
            farmDiv.innerHTML = '';

            // جلب بيانات المجموعات من الخادم بناءً على رقم المزرعة
            const farmId = this.ruleForm.FarmesId;

            fetch(`api/admin/Palms/GetGroups/${farmId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('فشل في جلب بيانات المجموعات');
                    }
                    return response.json();
                })
                .then(groups => {
                    // التحقق من وجود مجموعات
                    if (groups.length === 0) {
                        farmDiv.innerHTML = '<p>لا توجد مجموعات في هذه المزرعة.</p>';
                        return;
                    }

                    // إنشاء قائمة بالمجموعات
                    groups.forEach(group => {
                        const groupDiv = document.createElement('div');
                        groupDiv.style.overflowX = 'auto'; // إضافة تمرير أفقي
                        groupDiv.style.whiteSpace = 'nowrap'; // منع التفاف النص
                        groupDiv.style.border = '1px solid #ccc'; // لإضافة إطار
                        groupDiv.style.marginBottom = '10px'; // إضافة مسافة بين المجموعات
                        const groupTitle = document.createElement('h3');
                        groupTitle.style.textAlign = 'center';

                        groupTitle.textContent = group.name; // اسم المجموعة
                        groupDiv.appendChild(groupTitle);

                        // جلب بيانات النخيل لهذه المجموعة
                        this.loadPalmData(group.id, groupDiv);
                        farmDiv.appendChild(groupDiv);
                    });

                })
                .catch(error => {
                    farmDiv.innerHTML = `<p style="color:red;">${error.message}</p>`;
                });
        },

        loadPalmData(groupId, groupDiv) {
            // جلب بيانات النخيل من الخادم بناءً على معرّف المجموعة
            fetch(`api/admin/Palms/GetPalmsByGroup/${groupId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('فشل في جلب بيانات النخيل');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.length === 0) {
                        groupDiv.innerHTML += '<p>لا توجد بيانات للنخيل في هذه المجموعة.</p>';
                        return;
                    }

                    const minY = Math.min(...data.map(palm => palm.locationsY));
                    const maxY = Math.max(...data.map(palm => palm.locationsY));
                    const minX = Math.min(...data.map(palm => palm.locationsX));
                    const maxX = Math.max(...data.map(palm => palm.locationsX));

                    const table = document.createElement('table');
                    table.style.borderCollapse = 'collapse';
                    table.style.marginTop = '20px';

                    for (let i = minY; i <= maxY; i++) {
                        const row = document.createElement('tr');

                        for (let j = minX; j <= maxX; j++) {
                            const palm = data.find(p => p.locationsX === j && p.locationsY === i);
                            const cell = document.createElement('td');
                            cell.style.width = '50px';
                            cell.style.height = '50px';
                            cell.style.textAlign = 'center';
                            cell.style.border = '1px solid #ccc';
                            cell.style.cursor = 'pointer';
                            cell.style.position = 'relative';

                            if (palm) {
                                if (palm.isEmpty === 2) {
                                    cell.style.backgroundColor = '#00FF00'; // لون للخلايا الفارغة
                                }

                                const iconContainer = document.createElement('div');
                                iconContainer.style.display = 'flex';
                                iconContainer.style.flexDirection = 'row';
                                iconContainer.style.alignItems = 'center';
                                iconContainer.style.position = 'relative';

                                const palmIcon = document.createElement('span');
                                palmIcon.innerHTML = '🌴'; // رمز النخلة
                                palmIcon.style.fontSize = '20px';
                                palmIcon.style.marginRight = '13px';

                                const dropdownToggle = document.createElement('span');
                                dropdownToggle.innerHTML = '🛠️'; // رمز علامة الإعدادات (التروس)
                                dropdownToggle.style.cursor = 'pointer';
                                dropdownToggle.style.fontSize = '20px';
                                dropdownToggle.style.marginLeft = '13px';

                                const dropdown = document.createElement('div');
                                dropdown.style.display = 'none'; // إخفاء القائمة في البداية
                                dropdown.style.position = 'absolute';
                                dropdown.style.backgroundColor = '#fff';
                                dropdown.style.border = '1px solid #ccc';
                                dropdown.style.zIndex = '1000';
                                dropdown.style.left = '50%';
                                dropdown.style.transform = 'translateX(-50%)';
                                dropdown.style.marginTop = '5px';
                                dropdown.style.padding = '5px';

                                dropdown.innerHTML = `
                          <ul class="dropdown-list" style="list-style: none; padding: 6px; margin: 0;">
   <li>
    <a href="javascript:;"
       @click.prevent="this.OpentFertilizationDialog(palm)"
       type="button"
       title="التسميد"
       class="btn btn-icon btn-text-info waves-effect waves-light rounded-pill"
       data-bs-placement="top"
       aria-label="AddFertilization"
       data-bs-target="#AddFertilization"
       data-bs-toggle="modal"
       data-bs-original-title="secondary"
       style="display: flex; align-items: center; width: 150px; padding: 10px;">
        <i class="fas fa-seedling" style="font-size: 1.5em; margin-right: 10px;"></i>
        <span class="icon-text">التسميد</span>
    </a>
</li>
<li>
    <a href="javascript:;"
       @click.prevent="this.OpentPestsDialog(palm)"
       type="button"
       title="الآفات"
       class="btn btn-icon btn-text-danger waves-effect waves-light rounded-pill"
       data-bs-placement="top"
       aria-label="AddPests"
       data-bs-target="#AddPests"
       data-bs-toggle="modal"
       data-bs-original-title="secondary"
       style="display: flex; align-items: center; width: 150px; padding: 10px;">
        <i class="fas fa-exclamation-triangle" style="font-size: 1.5em; margin-right: 10px;"></i>
        <span class="icon-text">الآفات</span>
    </a>
</li>
<li>
    <a href="javascript:;"
       @click.prevent="this.OpentProductionDialog(palm)"
       type="button"
       title="الانتاج"
       class="btn btn-icon btn-text-success waves-effect waves-light rounded-pill"
       data-bs-placement="top"
       aria-label="AddProduction"
       data-bs-target="#AddProduction"
       data-bs-toggle="modal"
       data-bs-original-title="secondary"
       style="display: flex; align-items: center; width: 150px; padding: 10px;">
        <i class="fas fa-apple-alt" style="font-size: 1.5em; margin-right: 10px;"></i>
        <span class="icon-text">الانتاج</span>
    </a>
</li>
<li>
    <a href="javascript:;"
       @click.prevent="this.OpentCareDialog(palm)"
       type="button"
       title="العناية"
       class="btn btn-icon btn-text-secondary waves-effect waves-light rounded-pill"
       data-bs-placement="top"
       aria-label="AddCare"
       data-bs-target="#AddCare"
       data-bs-toggle="modal"
       data-bs-original-title="secondary"
       style="display: flex; align-items: center; width: 150px; padding: 10px;">
        <i class="fas fa-hand-holding-heart" style="font-size: 1.5em; margin-right: 10px;"></i>
        <span class="icon-text">العناية</span>
    </a>
</li>
<li>
    <a href="javascript:;"
       @click.prevent="this.OpentPreventiveOperationsDialog(palm)"
       type="button"
       title="العمليات الوقائية"
       class="btn btn-icon btn-text-info waves-effect waves-light rounded-pill"
       data-bs-placement="top"
       aria-label="AddPreventiveOperations"
       data-bs-target="#AddPreventiveOperations"
       data-bs-toggle="modal"
       data-bs-original-title="secondary"
       style="display: flex; align-items: center; width: 170px; padding: 10px;">
        <i class="fas fa-shield-alt" style="font-size: 1.5em; margin-right: 10px;"></i>
        <span class="icon-text">العمليات الوقائية</span>
    </a>
</li>
<li>
    <a href="javascript:;"
       @click.prevent="this.OpentSeedlingsDialog(palm)"
       type="button"
       title="انتاج الفسائل"
       class="btn btn-icon btn-text-success waves-effect waves-light rounded-pill"
       data-bs-placement="top"
       aria-label="AddSeedlings"
       data-bs-target="#AddSeedlings"
       data-bs-toggle="modal"
       data-bs-original-title="secondary"
       style="display: flex; align-items: center; width: 150px; padding: 10px;">
        <i class="fas fa-tree" style="font-size: 1.5em; margin-right: 10px;"></i>
        <span class="icon-text">انتاج الفسائل</span>
    </a>
</li>
    </ul>
`;

                                // إضافة الأيقونات إلى الحاوية
                                iconContainer.appendChild(dropdownToggle); // أضف السهم أولاً
                                iconContainer.appendChild(palmIcon); // ثم أضف الأيقونة
                                cell.appendChild(iconContainer); // إضافة الحاوية إلى الخلية
                                cell.appendChild(dropdown); // إضافة القائمة المنسدلة إلى الخلية

                                // إظهار أو إخفاء القائمة عند النقر على السهم
                                if (palm.isEmpty === 2) {
                                    dropdownToggle.addEventListener('click', (event) => {
                                        event.stopPropagation(); // منع الحدث من الانتقال إلى العنصر الأب
                                        const isVisible = dropdown.style.display === 'block';
                                        dropdown.style.display = isVisible ? 'none' : 'block'; // التبديل بين إظهار وإخفاء القائمة
                                    });
                                }
                                // عند النقر على الخلية (النخلة)، لا تغلق القائمة
                                cell.addEventListener('click', (event) => {
                                    event.stopPropagation(); // منع إغلاق القائمة المنسدلة
                                    this.openAddPalmDialog(i, j); // زيادة 1 للإحداثيات
                                    this.ruleForm.GroupsId=palm.groupsId
                                    this.ruleForm.Id=palm.id
                                    this.ruleForm.LocationsX=palm.locationsX
                                    this.ruleForm.LocationsY = palm.locationsY
                                    this.Getallinfo1();

                                    this.FertilizationFX(palm);
                                    this.PestsFX(palm);
                                    this.ProductionsFX(palm);
                                    this.CareFX(palm);
                                    this.PreventiveOperationsFX(palm);
                                    this.SeedlingsFX(palm);

                                });
                            }

                            row.appendChild(cell);
                        }

                        table.appendChild(row);
                    }

                    groupDiv.appendChild(table);
                })
                .catch(error => {
                    groupDiv.innerHTML = `<p style="color:red;">${error.message}</p>`;
                });

            // إخفاء القوائم المنسدلة عند النقر في أي مكان آخر
            document.addEventListener('click', (event) => {
                // تحقق مما إذا كان النقر داخل أي قائمة منسدلة
                const target = event.target;
                const dropdowns = groupDiv.querySelectorAll('div[style*="position: absolute;"]');

                dropdowns.forEach(dropdown => {
                    // إذا كان النقر داخل القائمة، فلا تفعل شيئًا
                    if (dropdown.contains(target)) {
                        return;
                    }
                    // إخفاء القائمة إذا لم يكن النقر داخلها
                    dropdown.style.display = 'none';
                });
            });
        },
        //    <strong>ID:</strong> ${palm.id}<br>
        //    <strong>FarmesId:</strong> ${palm.farmesId}<br>
        //    <strong>PalmsTypeId:</strong> ${palm.palmsTypeId}<br>
        //    <strong>IrrigationId:</strong> ${palm.irrigationId}<br>
        //    <strong>Classification:</strong> ${palm.classification}<br>
        //    <strong>FarmedOn:</strong> ${palm.farmedOn ? new Date(palm.farmedOn).toLocaleDateString() : 'غير محدد'}<br>
        //    <strong>LocationsX:</strong> ${palm.locationsX}<br>
        //    <strong>LocationsY:</strong> ${palm.locationsY}<br>
        //    <strong>IsEmpty:</strong> ${palm.isEmpty === 0 ? 'لا' : 'نعم'}<br>
        //`;
        //    },
        mounted() {
            this.createTable1();
        },
        openAddPalmDialog(row, col) {
            // تعيين مواقع النخلة
            this.ruleForm.LocationsX = col; // تحويل إلى 1-indexed
            this.ruleForm.LocationsY = row; // تحويل إلى 1-indexed

            // فتح نافذة الحوار
            // الكود لفتح النافذة هنا
        },

        showLocation(row, col) {
            // يمكنك استخدام alert أو أي وسيلة لعرض المعلومات
            this.ruleForm.LocationsX = col + 1; // تحويل إلى 1-indexed
            this.ruleForm.LocationsY = row + 1; // تحويل إلى 1-indexed      
        },
        ClearFertilization() {
            this.FertilizationForm.ParentId = '';
            this.FertilizationForm.FertilizationId = '';
            this.FertilizationForm.FertilizationDate = '';
            this.FertilizationForm.Quantity = '';
            this.FertilizationForm.Results = '';
            this.FertilizationForm.Id = '';

        },
        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (!this.Search)
                this.$blockUI.Start();
            this.$http.GetFarmes(this.pageNo, this.pageSize, this.Search)
                .then(response => {
                    if (!this.Search)
                        this.$blockUI.Stop();

                    this.Info = response.data.info;
                    this.pages = response.data.count;
                    this.StatisticsPalms = response.data.statisticsPalms;
                })
                .catch(() => {
                    if (!this.Search)
                        this.$blockUI.Stop();
                    this.pages = 0;
                });
        },


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    this.ruleForm.ExpectedProduction = Number(this.ruleForm.ExpectedProduction);
                    this.ruleForm.GroupsId = Number(this.ruleForm.GroupsId);
                    this.$blockUI.Start();
                    this.$http.AddPalms(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();

                            this.Clear2();
                            this.createTable1();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },


        OpentShowDialog(item) {
            this.ClearForm(this.ruleForm);

            this.SelectedItem = item;
            this.ruleForm.Id = item.id;

            this.ruleForm.FarmesId = item.id;
            this.ruleForm.CoordinateX = item.coordinateX;
            this.ruleForm.CoordinateY = item.coordinateY;
            //this.AddPalmDialog = true;
                        this.createTable1();

            this.state = 1;



        },




        submitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    this.ruleForm.ExpectedProduction = Number(this.ruleForm.ExpectedProduction);
                    this.ruleForm.GroupsId = Number(this.ruleForm.GroupsId);
                    this.$blockUI.Start();
                    this.$http.EditPalms(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.Clear2();
                            this.createTable1();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        DeletePalms(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    this.ruleForm.ExpectedProduction = Number(this.ruleForm.ExpectedProduction);
                    this.ruleForm.GroupsId = Number(this.ruleForm.GroupsId);
                    this.$blockUI.Start();
                    this.$http.DeletePalms(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.Clear2();
                            this.createTable1();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        Delete(Id) {
            Swal.fire({
                title: 'تأكيد العملية ',
                text: ' هـل انت متأكد من عملية الحذف  ؟',
                icon: 'question',
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false,
                confirmButtonText: `تأكيد العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteFarmes(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', 'حدت خطاء غير متوقع');
                            }
                        });
                    return;
                }
            });
        },
        //********************************************** عمليات التسميد **********************************************************
        FertilizationFX(palm) {
            this.ClearForm(this.FertilizationForm);
            this.FertilizationForm.PalmsId = palm.id;
            this.GetInfo1();
        },

        GetInfo1(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetPalmsFertilization(this.pageNo, this.pageSize, this.FertilizationForm.PalmsId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.PalmsFertilization = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        submitFormFertilization(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.FertilizationForm.Id = Number(this.FertilizationForm.Id);
                    this.FertilizationForm.PalmsId = Number(this.FertilizationForm.PalmsId);
                    this.FertilizationForm.FertilizationId = Number(this.FertilizationForm.FertilizationId);
                    this.FertilizationForm.Quantity = Number(this.FertilizationForm.Quantity);
                    this.$blockUI.Start();
                    this.$http.AddPalmsFertilization(this.FertilizationForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo1();
                            this.ClearFertilization();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpentFertilizationDialog(palm) {

            this.Getallinfo1();
            this.ClearForm(this.FertilizationForm);

            this.SelectedItem = palm;
            this.FertilizationForm.Id = palm.id;

            this.FertilizationForm.Quantity = palm.id;

        },
        ShowFertilizationDialog(item) {


            this.SelectedItem = item;
            this.FertilizationForm.Id = item.id;
            this.FertilizationForm.ParentId = item.fertilizationCompanyId;
            this.FertilizationForm.FertilizationId = this.GetAllFertilizations();
            this.FertilizationForm.FertilizationId = item.fertilizationId;
            this.FertilizationForm.FertilizationDate = item.fertilizationDate;
            this.FertilizationForm.Quantity = item.quantity;
            this.FertilizationForm.Results = item.results;

        },
        submitEditFormFertilization(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    this.FertilizationForm.PalmsId = Number(this.FertilizationForm.PalmsId);
                    this.FertilizationForm.FertilizationId = Number(this.FertilizationForm.FertilizationId);
                    this.FertilizationForm.Quantity = Number(this.FertilizationForm.Quantity);
                    this.$blockUI.Start();
                    this.$http.EditPalmsFertilization(this.FertilizationForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo1();
                            this.ClearFertilization();
                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        DeletesPalmsFertilization(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.FertilizationForm.Id = Number(this.FertilizationForm.Id);

                    this.$blockUI.Start();
                    this.$http.DeletesPalmsFertilization(this.FertilizationForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo1();
                            this.ClearFertilization();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        //********************************************** عمليات الافات **********************************************************
        ClearPests() {
            this.PestsForm.ParentId = '';
            this.PestsForm.PestsId = '';
            this.PestsForm.PesticidesId = '';
            this.PestsForm.PestsDate = '';
            this.PestsForm.Quantity = '';
            this.PestsForm.Results = '';
            this.PestsForm.Id = '';

        },
        PestsFX(palm) {
            this.ClearForm(this.PestsForm);
            this.PestsForm.PalmsId = palm.id;
            this.GetInfoPests();
        },

        GetInfoPests(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetPalmsPests(this.pageNo, this.pageSize, this.PestsForm.PalmsId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.PalmsPests = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        submitFormPests(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.PestsForm.Id = Number(this.PestsForm.Id);
                    this.PestsForm.PalmsId = Number(this.PestsForm.PalmsId);
                    this.PestsForm.PesticidesId = Number(this.PestsForm.PesticidesId);
                    this.PestsForm.PestsId = Number(this.PestsForm.PestsId);
                    this.PestsForm.Quantity = Number(this.PestsForm.Quantity);
                    this.$blockUI.Start();
                    this.$http.AddPalmsPests(this.PestsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoPests();
                            this.ClearPests();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpentPestsDialog(palm) {

            this.Getallinfo1();
            this.ClearForm(this.PestsForm);

            this.SelectedItem = palm;
            this.PestsForm.Id = palm.id;


        },
        ShowPestsDialog(item) {


            this.SelectedItem = item;
            this.PestsForm.Id = item.id;
            this.PestsForm.ParentId = item.companyId;
            this.PestsForm.PesticidesId = this.GetAllPesticidess();
            this.PestsForm.PesticidesId = item.pesticidesId;
            this.PestsForm.PestsId = item.pestsId;
            this.PestsForm.PestsDate = item.pestsDate;
            this.PestsForm.Quantity = item.quantity;
            this.PestsForm.Results = item.results;

        },
        submitEditFormPests(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    this.PestsForm.PalmsId = Number(this.PestsForm.PalmsId);
                    this.PestsForm.PesticidesId = Number(this.PestsForm.PesticidesId);
                    this.PestsForm.PestsId = Number(this.PestsForm.PestsId);
                    this.PestsForm.Quantity = Number(this.PestsForm.Quantity);
                    this.$blockUI.Start();
                    this.$http.EditPalmsPests(this.PestsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoPests();
                            this.ClearPests();
                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        DeletesPalmsPests(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.PestsForm.Id = Number(this.PestsForm.Id);

                    this.$blockUI.Start();
                    this.$http.DeletesPalmsPests(this.PestsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoPests();
                            this.ClearPests();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        //********************************************** عمليات الانتاج **********************************************************
        ClearProduction() {
            this.ProductionForm.ClassificationId = '';
            this.ProductionForm.ProductionDate = '';
            this.ProductionForm.Price = '';
            this.ProductionForm.Quantity = '';
            this.ProductionForm.Id = '';

        },
        ProductionsFX(palm) {
            this.ClearForm(this.ProductionForm);
            this.ProductionForm.PalmsId = palm.id;
            this.GetInfoProduction();
        },

        GetInfoProduction(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetPalmsProduction(this.pageNo, this.pageSize, this.ProductionForm.PalmsId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.PalmsProduction = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        submitFormProduction(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ProductionForm.Id = Number(this.ProductionForm.Id);
                    this.ProductionForm.PalmsId = Number(this.ProductionForm.PalmsId);
                    this.ProductionForm.ClassificationId = Number(this.ProductionForm.ClassificationId);
                    this.ProductionForm.Price = Number(this.ProductionForm.Price);
                    this.ProductionForm.Quantity = Number(this.ProductionForm.Quantity);
                    this.$blockUI.Start();
                    this.$http.AddPalmsProduction(this.ProductionForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoProduction();
                            this.ClearProduction();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpentProductionDialog(palm) {

            this.Getallinfo1();
            this.ClearForm(this.ProductionForm);

            this.SelectedItem = palm;
            this.ProductionForm.Id = palm.id;


        },
        ShowProductionDialog(item) {


            this.SelectedItem = item;
            this.ProductionForm.Id = item.id;
            this.ProductionForm.ClassificationId = item.classificationId;
            this.ProductionForm.ProductionDate = item.productionDate;
            this.ProductionForm.Price = item.price;
            this.ProductionForm.Quantity = item.quantity;

        },
        submitEditFormProduction(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ProductionForm.Id = Number(this.ProductionForm.Id);
                    this.ProductionForm.PalmsId = Number(this.ProductionForm.PalmsId);
                    this.ProductionForm.ClassificationId = Number(this.ProductionForm.ClassificationId);
                    this.ProductionForm.Price = Number(this.ProductionForm.Price);
                    this.ProductionForm.Quantity = Number(this.ProductionForm.Quantity);
                    this.$blockUI.Start();
                    this.$http.EditPalmsProduction(this.ProductionForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoProduction();
                            this.ClearProduction();
                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        DeletesPalmsProduction(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ProductionForm.Id = Number(this.ProductionForm.Id);

                    this.$blockUI.Start();
                    this.$http.DeletesPalmsProduction(this.ProductionForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoProduction();
                            this.ClearProduction();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        //********************************************** عمليات العناية **********************************************************
        ClearCare() {
            this.CareForm.TypeId = '';
            this.CareForm.Date = '';


        },
        CareFX(palm) {
            this.ClearForm(this.CareForm);
            this.CareForm.PalmsId = palm.id;
            this.GetInfoCare();
        },

        GetInfoCare(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetPalmsCare(this.pageNo, this.pageSize, this.CareForm.PalmsId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.PalmsCare = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        submitFormCare(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.CareForm.Id = Number(this.CareForm.Id);
                    this.CareForm.TypeId = Number(this.CareForm.TypeId);
                    this.$blockUI.Start();
                    this.$http.AddPalmsCare(this.CareForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoCare();
                            this.ClearCare();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpentCareDialog(palm) {

            this.Getallinfo1();
            this.ClearForm(this.CareForm);

            this.SelectedItem = palm;
            this.CareForm.Id = palm.id;


        },
        ShowCareDialog(item) {


            this.SelectedItem = item;
            this.CareForm.Id = item.id;
            this.CareForm.TypeId = item.typeId;
            this.CareForm.Date = item.date;


        },
        submitEditFormCare(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.CareForm.Id = Number(this.CareForm.Id);
                    this.CareForm.TypeId = Number(this.CareForm.TypeId);

                    this.$blockUI.Start();
                    this.$http.EditPalmsCare(this.CareForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoCare();
                            this.ClearCare();
                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        DeletesPalmsCare(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.CareForm.Id = Number(this.CareForm.Id);

                    this.$blockUI.Start();
                    this.$http.DeletesPalmsCare(this.CareForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoCare();
                            this.ClearCare();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        //********************************************** عمليات الوقائية **********************************************************
        ClearPreventiveOperations() {
            this.PreventiveOperationsForm.ParentId = '';
            this.PreventiveOperationsForm.PesticidesId = '';
            this.PreventiveOperationsForm.DateSpraying = '';
            this.PreventiveOperationsForm.Results = '';
            this.PreventiveOperationsForm.Descriptions = '';


        },
        PreventiveOperationsFX(palm) {
            this.ClearForm(this.PreventiveOperationsForm);
            this.PreventiveOperationsForm.PalmsId = palm.id;
            this.GetInfoPreventiveOperations();
        },

        GetInfoPreventiveOperations(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetPalmsPreventiveOperations(this.pageNo, this.pageSize, this.PreventiveOperationsForm.PalmsId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.PalmsPreventiveOperations = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        submitFormPreventiveOperations(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.PreventiveOperationsForm.Id = Number(this.PreventiveOperationsForm.Id);
                    this.PreventiveOperationsForm.PesticidesId = Number(this.PreventiveOperationsForm.PesticidesId);
                    this.$blockUI.Start();
                    this.$http.AddPalmsPreventiveOperations(this.PreventiveOperationsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoPreventiveOperations();
                            this.ClearPreventiveOperations();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpentPreventiveOperationsDialog(palm) {

            this.Getallinfo1();
            this.ClearForm(this.PreventiveOperationsForm);

            this.SelectedItem = palm;
            this.PreventiveOperationsForm.Id = palm.id;


        },
        ShowPreventiveOperationsDialog(item) {


            this.SelectedItem = item;
            this.PreventiveOperationsForm.Id = item.id;
            this.PreventiveOperationsForm.ParentId = item.companyId;
            this.PreventiveOperationsForm.PesticidesId = this.GetAllPesticidess();
            this.PreventiveOperationsForm.PesticidesId = item.pesticidesId;
            this.PreventiveOperationsForm.DateSpraying = item.dateSpraying;
            this.PreventiveOperationsForm.Results = item.results;
            this.PreventiveOperationsForm.Descriptions = item.descriptions;


        },
        submitEditFormPreventiveOperations(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.PreventiveOperationsForm.Id = Number(this.PreventiveOperationsForm.Id);
                    this.PreventiveOperationsForm.PesticidesId = Number(this.PreventiveOperationsForm.PesticidesId);

                    this.$blockUI.Start();
                    this.$http.EditPalmsPreventiveOperations(this.PreventiveOperationsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoPreventiveOperations();
                            this.ClearPreventiveOperations();
                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        DeletesPalmsPreventiveOperations(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.PreventiveOperationsForm.Id = Number(this.PreventiveOperationsForm.Id);

                    this.$blockUI.Start();
                    this.$http.DeletesPalmsPreventiveOperations(this.PreventiveOperationsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoPreventiveOperations();
                            this.ClearPreventiveOperations();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        //********************************************** عمليات انتاج الفسائل **********************************************************
        ClearSeedlings() {
            this.SeedlingsForm.Number = '';
            this.SeedlingsForm.DateCut = '';
            this.SeedlingsForm.StartDate = '';



        },
        SeedlingsFX(palm) {
            this.ClearForm(this.SeedlingsForm);
            this.SeedlingsForm.PalmsId = palm.id;
            this.GetInfoSeedlings();
        },

        GetInfoSeedlings(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            this.$blockUI.Start();
            this.$http.GetPalmsSeedlings(this.pageNo, this.pageSize, this.SeedlingsForm.PalmsId)
                .then(response => {
                    this.$blockUI.Stop();

                    this.PalmsSeedlings = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },
        submitFormSeedlings(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.SeedlingsForm.Id = Number(this.SeedlingsForm.Id);
                    this.SeedlingsForm.Number = Number(this.SeedlingsForm.Number);
                    this.$blockUI.Start();
                    this.$http.AddPalmsSeedlings(this.SeedlingsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoSeedlings();
                            this.ClearSeedlings();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الإظافة', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OpentSeedlingsDialog(palm) {

            this.Getallinfo1();
            this.ClearForm(this.SeedlingsForm);

            this.SelectedItem = palm;
            this.SeedlingsForm.Id = palm.id;


        },
        ShowSeedlingsDialog(item) {


            this.SelectedItem = item;
            this.SeedlingsForm.Id = item.id;
            this.SeedlingsForm.Number = item.number;
            this.SeedlingsForm.DateCut = item.dateCut;
            this.SeedlingsForm.StartDate = item.startDate;



        },
        submitEditFormSeedlings(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.SeedlingsForm.Id = Number(this.SeedlingsForm.Id);
                    this.SeedlingsForm.Number = Number(this.SeedlingsForm.Number);

                    this.$blockUI.Start();
                    this.$http.EditPalmsSeedlings(this.SeedlingsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoSeedlings();
                            this.ClearSeedlings();
                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
        DeletesPalmsSeedlings(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.SeedlingsForm.Id = Number(this.SeedlingsForm.Id);

                    this.$blockUI.Start();
                    this.$http.DeletesPalmsSeedlings(this.SeedlingsForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfoSeedlings();
                            this.ClearSeedlings();

                            //this.$helper.DestroyElemntById('Close');
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            if (err.response && err.response.data) {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', err.response.data);
                            } else {
                                this.$helper.ShowMessage('error', 'خطأ بعملية الحدف', 'حدت خطاء غير متوقع');
                            }
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },






        //Suspends







        //InfoDialog




        //Support Info
        Back() {
            this.state = 0;
            this.ClearForm(this.ruleForm);
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },


    }
}
